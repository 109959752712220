<div class="grid">
  <div class="col-3">
    <h3>Ingest Data  </h3>
  </div>
  <div class="col-9">
     
  </div>

  <!-- <div class="col-3">
    <strong>Start Date</strong>
  </div>
  <div class="col-3">
    <p-calendar [(ngModel)]="ingestionConfig.startDate"></p-calendar>
  </div>
  <div class="col-6">

  </div> -->

  <!-- <div class="col-3">
    <strong>End Date</strong>
  </div>
  <div class="col-3">
    <p-calendar [(ngModel)]="ingestionConfig.endDate"></p-calendar>
  </div>
  <div class="col-6">

  </div> -->

  <!-- <div class="col-3">
    <strong>Overwite</strong>
  </div>
  <div class="col-3">

    <p-checkbox [(ngModel)]="ingestionConfig.overwriteAll" [binary]="true"></p-checkbox>

  </div>
  <div class="col-6">

  </div> -->

  <div class="col-3">
    
  </div>
  <div class="col-3">
    <p-button type="button" label="Ingest Data" class="p-button-primary" (click)="ingestData()"></p-button>
  </div>
  <div class="col-6">

  </div>
</div>


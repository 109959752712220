import { Injectable } from '@angular/core';
import { ApiService } from '../../shared/api/api.service';
import { AuthService } from '../../auth/shared/auth.service';
import { UtilService } from '../../shared/util/util.service';
 
//import { IngestTenantDataDto } from './dto/ingest-tenant-data-dto';

@Injectable({
  providedIn: 'root'
})
export class ManagementService {


  private _tenantId: string = "";
  private _productId: string = "";

  public constructor(private _apiService: ApiService, private _authService: AuthService, private _utilService: UtilService) {


    this._tenantId = this._authService.getUserSession().tenantId;
    this._productId = this._authService.getUserSession().productId;



  }

  
  // public IngestData = (ingestionConfig : IngestTenantDataDto): void => {

  //   var url = `api/v1/insights/ingestion/${this._productId}/${this._tenantId}/ingest`;
  //   var dto = new IngestTenantDataDto();
  //   ingestionConfig.tenantId = this._tenantId;
  //   ingestionConfig.productId = this._productId;
  //   this._apiService.post(url, ingestionConfig)


  // }

}
